import { render, staticRenderFns } from "./SpaceInsertPeriod.vue?vue&type=template&id=70fdc60c&scoped=true"
import script from "./SpaceInsertPeriod.vue?vue&type=script&lang=js"
export * from "./SpaceInsertPeriod.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/space/spaceinsert.css?vue&type=style&index=0&id=70fdc60c&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/space/spaceperoid.css?vue&type=style&index=1&id=70fdc60c&prod&scoped=true&lang=css&external"
import style2 from "@/assets/css/modal/space/playstop.css?vue&type=style&index=2&id=70fdc60c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fdc60c",
  null
  
)

export default component.exports